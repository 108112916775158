<template>
  <div id="app">
    <div class="maxwidth-theme">
      <div class="calc">
        <h2 class="calc__title">Cколько тонн в кубе: перевод из тонн в м3</h2>

        <div class="calc__img">
          <img
            :src="require('@/assets/calc.png')"
            alt="calc"
          />
        </div>

        <div class="calc__content">
          <div>Выберите материал</div>

          <div>
            <select
              class="calc__select"
              v-model="density"
              @change="calc('', '')"
            >
              <template v-for="(item, index) in materials">
                <option
                  :value="item.value"
                  :key="`option-${index}`"
                  :select="item.value === density"
                >{{ item.name }}</option>
              </template>
            </select>
          </div>

          <div>Введите количество</div>

          <div>
            <input
              class="calc__input"
              :value="weight"
              @input="calc(+$event.target.value, 'weight')"
            />

            <span class="calc__small"> ТН.</span>

            <input
              class="calc__input"
              :value="volume"
              @input="calc(+$event.target.value, 'volume')"
            />

            <span class="calc__small"> М<sup>3</sup></span>
          </div>

          <div>
            <div class="callback" data-event="jqm" data-param-id="24" data-name="callback">
              <a href="javascript:;" rel="nofollow" class="btn btn-default btn-xs calc__btn">
                Заказать
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    materials: [
      {
        name: 'Песок карьерный',
        value: 1.45,
      },
      {
        name: 'Песок речной',
        value: 1.45,
      },
      {
        name: 'Щебень',
        value: 1.25,
      },
      {
        name: 'ПГС',
        value: 1.86,
      },
      {
        name: 'Асфальт',
        value: 2.4,
      },
      {
        name: 'Доломит',
        value: 1.4,
      },
      {
        name: 'Керамзит',
        value: 0.3,
      },
    ],
    density: 1.45,
    weight: 0,
    volume: 0,
  }),
  methods: {
    calc(value, type) {
      if (type === 'volume') {
        this.volume = value;
        this.weight = (this.volume * this.density).toFixed(2);
      } else if (type === 'weight') {
        this.weight = value;
        this.volume = (this.weight * (1 / this.density)).toFixed(2);
      } else {
        this.weight = (this.volume * this.density).toFixed(2);
      }
    },
  },
};
</script>

<style lang="scss">
  .calc {
    background: #EFEFEF;
    font-size: 24px;
    line-height: 36px;
    display: grid;
    grid-template-areas: 'title title'
                         'img content';
    grid-template-columns: 160px 1fr;
    padding: 25px 50px 50px;
    column-gap: 50px;
    row-gap: 30px;
    align-items: flex-start;

    &__title {
      font-weight: 400;
      font-size: 26px;
      line-height: 48px;
      text-align: center;
      grid-area: title;
      margin-bottom: 0;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 26px;
      }
    }

    &__img {
      grid-area: img;

      img {
        object-fit: scale-down;
        object-position: center;
        width: 100%;
        min-height: 100%;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    &__content {
      grid-area: content;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      gap: 30px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 0;
        text-align: center;
      }

      @media (max-width: 575px) {
        gap: 15px;
      }
    }

    &__select {
      width: 100%;
      max-width: 290px;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 6px;
      padding: 5px 15px;
      font-size: 18px;

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }

      @media (max-width: 575px) {
        margin-bottom: 0;
      }
    }

    &__input {
      width: 100%;
      max-width: 100px;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 6px;
      padding: 5px 15px;
      font-size: 18px;

      &:nth-child(3) {
        margin-left: 25px;

        @media (max-width: 575px) {
          margin-left: 15px;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }

      @media (max-width: 575px) {
        margin-bottom: 0;
        max-width: 90px;
      }
    }

    &__small {
      font-size: 18px;
      line-height: 36px;

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }

    &__btn {
      width: 100%;
      max-width: 180px;
      height: 36px;
      margin: 0;
      font-size: 18px;
      line-height: 36px;
      padding: 0 15px;
    }

    @media (max-width: 991px) {
      grid-template-areas: 'title'
                           'content';
      grid-template-columns: 1fr;
    }

    @media (max-width: 767px) {
      row-gap: 15px;
    }

    @media (max-width: 575px) {
      padding: 25px 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }
</style>
